html, body, * {
  cursor: url("ui-cursor-smol.2f8c085c.png") 1 1, pointer !important;
}

html button:hover, body button:hover, * button:hover, html button:active, body button:active, * button:active {
  cursor: url("ui-cursor-active-highlight-smol.37765e44.png") 1 1, pointer !important;
}

html canvas, body canvas, * canvas {
  cursor: url("cursor-smol.73a8eaa7.png") 16 16, pointer !important;
}

/*# sourceMappingURL=index.9aa88038.css.map */
